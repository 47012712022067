/* App.css */

/* Define color classes */
.color-wh { color: white; }
.color-cy { color: #61dafb; }
.color-gr { color: green; }
.color-ye { color: yellow; } 
.color-dg { color: rgb(121, 121, 121) } /* Dark-GRAY */
.color-lg { color: rgb(220, 220, 220) } /* Light-GRAY */
.color-or { color: orange } 
.color-re { color: red } 

body, html {
  height: 100%;
  margin: 0;
  
  /* Add a background gradient */
  background: linear-gradient(to top left, #041117, #0a242d, #3e546f);
  
  color: white;
  font-size: 2.0em;
  font-family: 'Consolas', monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the start */
  align-items: center; /* Center content horizontally */
  height: 100vh; /* Use full viewport height */
  padding: 0 4vh; /* Adjust horizontal padding */
  box-sizing: border-box;
  position: relative; /* Ensures that the position is relative to the viewport */
}

.App-header {
  width: 100%; /* Ensures the header spans the full width */
  padding-top: 2vh; /* Adds some padding at the top */
  padding-bottom: 2vh; /* Adds padding below to space out from the code container */
}

.logo {
  width: 250px; /* Adjust size as needed */
  margin-bottom: 20px; /* Adjust bottom margin to space out from the header text */
}

.App-header .logo {
  width: 250px; /* Adjust size as needed */
  margin-bottom: 0px; /* Reduce margin to help with centering */
  margin-top: 3vh; /* Push logo down a bit */
}

.App-header h3 {
  color: #364558;
  font-family: 'Roboto', sans-serif; 
  font-size: 1.7vw; /* Responsive font size */
  font-size: clamp(1.7vw, 1.7vw, 1.7vw); /* Responsive font size */
  font-weight: 400; /* Bold font */
  max-width: 85%; /* Limit width to 65% of the container */
  margin: 0 auto; /* Center the text */
  padding-top: 5vh;
  margin-bottom: 40px; /* Add margin to the bottom */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden;
  text-overflow: ellipsis;
}

.code-container {
  position: relative; /* Ensure the container is positioned relatively for absolute children like buttons */
  display: flex;
  justify-content: center;  /* Center content horizontally */
  align-items: center;      /* Center content vertically */
  background-color: #000;
  padding: 20px 20px;       /* Adjust padding as needed */
  transition: all 0.7s ease-in-out;  /* Smooth transition for any changes */
  width: 97%;               /* Adjust width to leave space for margins */
  max-width: 1200px;        /* Max width can be adjusted based on design requirements */
  margin: 10px auto;        /* Vertical margin for spacing, horizontal auto for centering */
  height: 10vh;             /* Initial height, adjust as needed */
  line-height: 0.65;        /* Adjust line height for text within the container */
}

.code-container.demo-active {
  height: 40vh; /* Expanded height using viewport height */
  align-items: flex-start; /* Align items to the top */
  justify-content: flex-start; /* Ensure they start from the top */
  flex-direction: column; /* Align items vertically */
}

pre {
  width: 95%;
  text-align: center; /* Ensure text is left-aligned */
  
  white-space: pre-wrap; /* Handle wrapping */
  padding-left: 2vh; /* Indent code */
  transition: font-size 0.5s ease, width 0.5s ease, text-align 0.5s ease; /* Animate font size, width, and text alignment */
}

pre.demo-active {
  
  width: 85%; /* Expand width during demo */
  text-align: left; /* Left align text during demo */
  margin-top: 10px; /* Controls where commands starts from the top */
}

.oneliner {
  font-size:  1.6vw; /* Responsive font size */
  font-weight: 100; /* Bold font */
  font-family: 'Consolas', monospace;
  transition: font-size 0.5s ease, transform 0.5s ease, text-align 0.5s ease; /* Animate font size, transform, and text alignment */

}

.oneliner.demo-active {
  font-size: clamp(0.15em, 0.9vw, 1em); /* Responsive font size */
  transform: translateX(-50%); /* Move further to the left */
  width: 90%; /* Expand width during demo */
  text-align: left; /* Left align text during demo */
  line-height: 0.6;
}

.copy-btn {
  position: absolute; /* Position the button absolutely within the container */
  top: 20px; /* Adjust positioning as needed */
  right: 50px; /* Adjust positioning as needed */
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #61dafb;
  font-size: clamp(2vw, 1.8vw, 1.8vw); /* Responsive font size */
  transition: opacity 0.5s ease, transform 0.1s ease, color 0.1s ease; /* Transition for smooth animation */
}
   /* transition: transform 0.1s ease; Transition for smooth animation */

.copy-btn.copied {
  animation: copy-animation 0.6s ease;
}
.copy-btn.fade-out {
  animation: fade-out 0.5s forwards;
}

.copy-btn.hidden {
  display: none; /* Hide the copy button */
}

@keyframes copy-animation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.copy-btn:hover {
  color: #b6ecfb;
}

.App-footer {
  position: fixed;  /* Fix position relative to the viewport */
  bottom: 4vh;        /* Align to the bottom of the viewport */
  width: 100%;      /* Ensure it spans the full width of the screen */
  padding: 10px 0;  /* Adjust padding as needed */
  background: transparent;  /* Transparent background */
  text-align: center; /* Center the content */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.demo-btn, .demo-step-btn {
  background-color: transparent;
  border: none;
 
  cursor: pointer;
  color: #364558;
  font-size: 0.65em;
  font-weight: lighter; /* Fallback if 300 is not available */
  transition: transform 0.3s ease, color 0.3s ease; /* Transition for smooth animation */

   /* transition: transform 0.1s ease; Transition for smooth animation */
}
.demo-step-btn {
  color: transparent
}

.demo-btn.active, .demo-step-btn.active { 
  color: #364558;
}

.demo-btn:hover, .demo-step-btn:hover  { 
  color: #61dafb;
}

/* Hover effect only for non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .demo-btn:hover {
    color: #b6ecfb; /* Hover state color */
  }
}

@media (max-width: 768px) {
  .App-header h3 {
    font-size: 4vw;
  }
  .copy-btn {
    padding: 0px 0px;
    font-size: 0.3em;
    right: 8px;
  }
  .App-header .logo {
    width: 180px; /* Adjust size for smaller screens */
  }

  .oneliner {
    font-size: clamp(2vw, 3vw, 3vw); /* Responsive font size */

  }


  .code-container {
    padding: 5px 5px; /* Reduce padding for smaller screens */
    margin-top: 5px; /* Reduce margin for smaller screens */
    line-height: 0.5; /* Codespacing between lines */
    height: 8vh; /* Initial height using viewport height */
  }
}

@media (max-width: 480px) {
  .App {
    padding: 2vh 2vh; /* Reveals background on sides of code container */
  }
  .App-header h3 {
    font-size: 5.5vw;
    max-width: 97%; 
  }
  .copy-btn {
    font-size: 0.25em;
  }
  pre {

    padding-left: 0.5vh; /* Indent code */
  }
  .oneliner {
    font-size: 3.6vw;
  }
  
  .code-container {
    margin-top: 5px; /* Reduce margin */
    padding: 5px 5px; /* Reduce padding */
    line-height: 0.1vh; /* Adjust line height */
  }
  .code-container.demo-active {
    height: 35vh; /* Expanded height using viewport height */
  }
}
